.dropdown .material-icons {
    font-size: 30px;
}
.navbar-nav {
    padding-right: 20px;
}

.dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}
.dropdown-menu {
    margin-left: -6.8em;
 
}
.dropdown-menu a{
    color:black !important;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    padding: 5px;
}
.dropdown-menu:after,
.dropdown-menu:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.dropdown-menu:after {
    border-color: rgba(136, 23, 23, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    left: 85%;
    margin-left: -19px;
}
.dropdown-menu a .material-icons {
    margin: 0px;
    padding: 3px 10px 0px 0px;
    color: black;
    font-size: 20px;
  }
/*.dropdown {
  position: relative;
}
.dropbtn {
  cursor: pointer;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
  z-index: 134;
  margin-left: -11.5em;
}

.dropdown-content:after,
.dropdown-content:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.dropdown-content:after {
  border-color: rgba(136, 23, 23, 0);
  border-bottom-color: #ffffff;
  border-width: 10px;
  left: 85%;
  margin-left: -19px;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content .dropdown-contentli {
  list-style-type: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: bolder;
  display: flex;
  padding: 5px;
  color:black !important;

}
.dropdown-content hr {
  border: 1px solid;
  background-color: black;
}
.dropdown-content .dropdown-contentli:hover {
    color:rgb(172, 166, 255) !important;
    
}
.dropdown-content .dropdown-contentli .material-icons {
  margin: 0px;
  padding: 0px 10px 0px 0px;
  color: black;
  font-size: 20px;
}
*/
.navbar-brand img{
  position: absolute;
  width: 8%;
 margin-top: -1.6%;
}
@media  (max-width: 550px){
  .navbar-nav {
    width: 100%;
    text-align: center;
    height: 70vh;
}
.navbar-brand img{
  position: absolute;
  width: 30%;
 margin-top: -6.5%;
}
.navbar-nav>li {
  padding-right: 50px;
  margin: auto;
  text-align :center;
  font-size: 20px;
}

}